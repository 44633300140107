import { httpGet, httPost } from "./http";
/**
 * 获取表格分页数据
 * @param {} params
 * @returns
 */
export function getTableListByPage(params) {
  return httpGet("/zy/page", params);
}

export function createData(data) {
  return httPost("/zy/create", data);
}

export function editData(data) {
  return httPost("/zy/edit", data);
}

export function deleteData(data) {
  return httPost("/zy/delete", data);
}
