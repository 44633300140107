<template>
  <div id="zyOrder">
    <div style="display: flex">
      <span class="orderDate">今晚预约：{{ todayOrderDate }}</span>
      <div style="text-align: right">
        <a-button type="primary" @click="addData"> 新增 </a-button>
      </div>
    </div>
    <a-table :columns="columns" :data-source="data" :pagination="false">
      <template slot="timeInterval" slot-scope="timeInterval">
        <span v-if="timeInterval === '0'"> 上午 </span>
        <span v-else> 下午 </span>
      </template>

      <template slot="status" slot-scope="status">
        <span
          class="status"
          v-if="status.status === '0'"
          @click="goErrorMsgDialog(status)"
        >
          待预约
        </span>
        <span class="status success" v-else-if="status.status === '1'">
          预约成功
        </span>
        <span class="status error" v-else-if="status.status == '2'">
          预约失败
        </span>
        <span class="status" v-else>无状态</span>
      </template>

      <template slot="operator" slot-scope="row">
        <span>
          <a @click="handleAdd(row)">复制</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(row)">编辑</a>
          <a-divider type="vertical" />
          <a style="color: rgb(229, 81, 81)" @click="handleDelete(row.id)"
            >删除</a
          >
        </span>
      </template>
    </a-table>
    <a-pagination
      :current="currentPage"
      :total="totalSize"
      :show-total="(total) => `共计 ${total} 条`"
      :page-size.sync="pageSize"
      @change="onPageChange"
    />
    <addOrEditDialog
      ref="addOrEdit"
      @refreshTable="getTableDatas"
    ></addOrEditDialog>
    <a-modal
      :visible="visibleErrMsgDialog"
      title="Error log"
      @cancel="handleclose"
    >
      <template slot="footer">
        <a-button @click="handleclose">关闭</a-button>
      </template>
      <div class="errContent">{{ errorMsg }}</div>
    </a-modal>
  </div>
</template>

<script>
import addOrEditDialog from "@/components/ctgOrder/addOrEdit";
import { getTableListByPage, deleteData } from "@/plugins/request/ctgOrder";
import { getAfterDate } from "@/plugins/utils.js";
const columns = [
  {
    title: "姓名",
    dataIndex: "name",
    minWidth: 120,
    key: "name",
    minWidth: 120
  },
  {
    title: "拍摄团队",
    dataIndex: "photographer",
    key: "photographer",
  },
  {
    title: "电话",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "日期",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "时间",
    dataIndex: "timeInterval",
    key: "timeInterval",
    scopedSlots: { customRender: "timeInterval" },
  },
  {
    title: "状态",
    // dataIndex: "status",
    key: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "operation",
    width: 200,
    scopedSlots: { customRender: "operator" },
  },
];
// "Authors" table list of rows and their properties.
const data = [];
export default {
  components: {
    addOrEditDialog,
  },
  data() {
    return {
      data,
      columns,
      showAddOrEditDialog: false,
      dialogTitle: "新增",
      currentPage: 1,
      pageSize: 10,
      totalSize: 0,
      todayOrderDate: "",
      visibleErrMsgDialog: false, //error 弹窗
      errorMsg: "", // error信息
    };
  },
  mounted() {
    this.getTableDatas();
    let date = new Date();
    let today =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.todayOrderDate = getAfterDate(today, 7);
  },
  methods: {
    getTableDatas() {
      let params = {
        pageNo: this.currentPage - 1,
        pageSize: this.pageSize,
      };
      getTableListByPage(params)
        .then((res) => {
          if (true) {
            this.data = res.data.content;
            this.totalSize = res.data.totalElements;
          } else {
            this.$notification["error"]({
              message: "获取数据失败！",
            });
          }
        })
        .catch((err) => {
          this.$notification["error"]({
            message: err,
          });
        });
    },
    onPageChange(val) {
      this.currentPage = val;
      this.getTableDatas();
    },
    addData() {
      this.$refs.addOrEdit.openDialog("新增预约");
    },
    handleOk() {
      this.showAddOrEditDialog = false;
    },
    handleEdit(data) {
      this.$refs.addOrEdit.openDialog("编辑预约", data);
    },
    handleAdd(data){
      data.id = "";
      this.$refs.addOrEdit.openDialog("复制预约", data);
    },
    handleDelete(id) {
      let that = this;
      this.$confirm({
        title: "确定删除该数据吗?",
        onOk() {
          deleteData({ id: id }).then((res) => {
            console.log(this);
            console.log(that);
            that.getTableDatas();
            that.$notification["success"]({
              message: "删除成功",
            });
          });
        },
      });
    },
    goErrorMsgDialog(row) {
      console.log(row);
      this.errorMsg =
        "PHP报错信息的分类及处理方法介绍PHP作为一种服务器端编程语言，我们在开发过程中难免会遇到各种报错信息。了解和处理这些报错信息对于定位和解决问题是非常重要的。本文将介绍PHP报错信息的分类及相应的处理方法，并提供代码示例。常见的PHP报错信息2.1 语法错误语法错误是代码中最常见的错误之一，也是最容易发现的。当我们的代码中存在语法错误时，PHP解析器会显示相应的错误信息，并指出具体的代码行数。示例1：语法错误";
      this.visibleErrMsgDialog = true;
    },
    handleclose() {
      this.visibleErrMsgDialog = false;
    },
  },
};
</script>

<style lang="scss">
#zyOrder {
  .orderDate {
    font-size: 18px;
    margin-left: 20px;
    line-height: 40px;
    flex: 1;
  }
  .copy-icon {
    color: #3583ca;
    font-size: 16px;
    margin-left: 15px;
    cursor: pointer;
    position: absolute;
  }
  .status {
    display: inline-block;
    width: 70px;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    line-height: 30px;
    background-color: #ff8e00;
    color: #ffffff;
  }
  .success {
    background-color: #09d315;
  }
  .error {
    background-color: #f23d33;
    cursor: pointer;
  }
  .ant-table-content{
    overflow-x: auto;
  }
  .ant-table-tbody > tr > td {
    white-space: nowrap
  }
  .ant-modal-body{
    padding: 15px
  }
}
.errContent {
  line-height: 28px;
}
</style>
